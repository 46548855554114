.crooss-btn {
  border: none;
  background-color: transparent;
  position: relative;
  width: 24px;
  height: 24px;
  --weight: 2px;
  --aa: 1px;
  --color: #FEFEFE;
  border-radius: 3px;
  cursor: pointer;
  background: linear-gradient(45deg, transparent calc(50% - var(--weight) - var(--aa)),
                               var(--color) calc(50% - var(--weight)), var(--color) calc(50% + var(--weight)), 
                               transparent calc(50% + var(--weight) + var(--aa))), 
                               linear-gradient(-45deg, transparent calc(50% - var(--weight) - var(--aa)), 
                               var(--color) calc(50% - var(--weight)), var(--color) calc(50% + var(--weight)), 
                               transparent calc(50% + var(--weight) + var(--aa)));
  margin-right: 2%;
}
