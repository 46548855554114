.panel {
  height: 60vh;
  width: 40vw;
  background: rgba(196, 196, 196, 0.1);
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.25),
    inset 0px -4px 4px 4px rgba(237, 237, 237, 0.15);
  backdrop-filter: blur(15px);
  border-radius: 50px;
  padding: 41px 39px 34px 53px;
  display: flex;
  flex-direction: column;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.panel__text {
  margin-top: 3vh; /* 59px*/
  font-style: normal;
  font-weight: normal;
  font-size: 2vw;
  line-height: 40px;
  color: #f7f7f7;
  text-shadow: 4px 4px 4px #000000;
}

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin-left: 37px;
  z-index: 100000;
  transition: transform 1s ease;
}

.wrapper.visible {
  transform: translateY(0);
}

.panel-open-btn {
  position: absolute;
  left: 20px;
  top: -15px;
  transform: translateY(-100%);
}

.button-open-btn{
  background: transparent;
  border: 4px solid rgba(196, 196, 196, 0.8);
  border-radius: 10px;
  padding: 5px 14px;
  font-family: "Neucha";
  font-weight: normal;
  color: #f7f7f7;
  font-size: 5.5vw;
  box-shadow: 1px 4px 4px #000000;
}

.panel__close-btn {
  display: none;
}

.panel-open-btn {
  display: none;
}
@media (max-width: 912px) {
  .wrapper {
    margin: 0;
    width: 100vw;
    transform: translateY(100%);
  }
  .panel {
    height: 62.5vh;
    width: 83vw;
    padding: 20px;
  }
  .panel__text {
    font-size: 5vw;
  }
  .panel__title-wrapper {
    display: none;
  }
  .panel__close-btn {
    display: flex;
    justify-content: flex-end;
  }
  .panel-open-btn {
    display: block;
    animation: visible 1.1s;
  }

  @keyframes visible {
    0%{
      opacity: 0;
    }

    80%{
      opacity: 0;
    }

    100%{
      opacity: 1;
    }
  }
}
