.popup-title {
    position: absolute;
    border: 4px solid #f2f2f2;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 49px;
    padding: 14px;
    cursor: pointer;
    transition: all 0.4s ease-out;
}

.popup-title img {
    width: 32px;
}

.popup-title__text {
    display: none;
    width: 250px;
    font-family: "Neucha";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    text-transform: uppercase;
    color: #ffffff;
}

.popup-title--active {
    position: absolute;
    border: 4px solid #f2f2f2;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 49px;
    padding: 14px;
    border-radius: 49px;
    padding-right: 50px;
    transition: all 0.4s ease-in;
}

.popup-title--active img {
    margin-right: 10px;
    width: 32px;
}

.popup-title__text.active {
    display: flex;
    justify-content: space-between;
}

.popup__body {
    display: none;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 26px;
    color: #f2f2f2;
    position: absolute;
    padding: 16px 20px 40px;
    bottom: 0;
    transform: translateY(100%);
    word-wrap: break-word;
    left: 5%;
    width: 90%;
    border: 5px solid #e4dddd;
    box-sizing: border-box;
    border-radius: 0px 0px 54px 54px;
    -ms-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.popup__body__text {
    opacity: 0;
    overflow: hidden;
    animation: showText 0.3s forwards;
    animation-delay: 0.4s;
}

@keyframes showText {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.25;
    }
    100% {
        opacity: 1;
    }
}

.popup__body.active {
    display: inline-block;
    max-height: 360px;
}
