.loading{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading__wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.loading__text{
    margin-top: 30px;
    color: #F3F3F3;
    font-size: 42px;
}

.loading__spinner-wrap{
    width: 90px;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content:space-between;
  }
  
  .loading__spinner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .spinner__body{
    width: 18px;
    height: 18px;
    background-color: #F3F3F3;
    animation: rotateRec 3s forwards;
    animation-iteration-count: infinite;
    box-shadow: 0px 0px 5px #1b1b1b1f;
  }
  
  @keyframes rotateRec {
    0%{
        border-radius: 0%;
    }
  
    50%{
        transform :rotate(-360deg) scale(0.7);
        border-radius: 100%;
    }
  
    100%{
        border-radius: 0%;
    }
  }
  
  .spinner__shadow{
    width: 18px;
    height: 5px;
    background-color: #1b1b1b6c;
    margin-top: 10px;
    border-radius: 100%;
    animation: scaleShadow 3s forwards;
    animation-iteration-count: infinite;
  }
  
  @keyframes scaleShadow {
    0%{
      background-color: #1b1b1b6c;
    }
  
    50%{
        transform :scale(0.7);
        background-color: #1b1b1b1f;
    }
  
    100%{
      background-color: #1b1b1b6c;
    }
  }