.panel__container {
    display: grid;
    margin-top: 5%;
    grid-template-columns: repeat(auto-fit, 300px);
    row-gap: 30px;
    column-gap: 2.5vw;
    overflow: hidden;
    justify-content: center;
}

@media(max-width: 912px){
    .panel__container{
        padding:  0px 28px;
        grid-template-columns: 1fr;
    }
}