.container__item {
    padding: 16px 18px;
    background: #A9A9A9;
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    border: none;
    display: flex;
    align-items: center;
    width: 100%;
}

.item__wrapper{
    margin-right: 18px;
}
.container__item:hover {
    background: #31D12B;
}
.item__title{
    font-style: normal;
    font-weight: normal;
    font-size: 1.8vw;
    line-height: 26px;
    font-family: "Neucha";
    text-transform: uppercase;
    color: #F7F7F7;
    text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    text-align: left;
}

@media (max-width: 912px) {
    .item__title{
        font-size: 5vw;
    }
  }